/* eslint-disable camelcase */
import React, { useEffect, useMemo, useRef } from 'react'
import { Link, VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import { Image } from '~/components/Image'
import { getImageSrc } from '~/helpers'
import { useAlgoliaSearchInsignts } from '~/hooks'
import { GTM, GTMEvent } from '~/lib'
import { useProductDataLayer } from '~/lib/gtm/hooks'
import { Button } from '../Button'
import { BannerButtonSizeClassMap } from '../Button/Button.types'
import RichText from '../RichText/RichText'
import { SalePrice } from '../SalePrice'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './OfferCard.module.css'
import { OfferCardProps } from './OfferCard.types'
import { OfferImage } from './OfferImage'
import { OfferSticker } from './OfferSticker'

export const OfferCard = ({
  backgroundColor,
  size = 'large',
  label,
  title,
  titleText,
  description,
  shopNow,
  image,
  imagePosition = 'center-center',
  productImage,
  brandLogo,
  disclaimer,
  skus,
  sticker,
  price,
  saveText,
  wasText,
  brandName,
  category1Name,
  category2Name,
  category3Name,
  category4Name,
  category5Name,
  listId,
  listName,
  item_status_tags,
  promotionId,
  promotionName,
  creativeName,
  creativeSlot,
  productId,
  textColor,
  buttonPosition,
}: OfferCardProps) => {
  const isLarge = size === 'large'
  const typographyStyle = { color: textColor }
  const richTextWrapperStyle = {
    '--color-typography-heading': textColor,
    '--color-secondary-muted': textColor,
  } as React.CSSProperties
  const priceStyles = classNames('mt-4 w-max max-lg:h-6', styles.salesPrice, {
    'lg:mt-8 md:mt-4': isLarge,
    [styles.largeSalesPrice]: isLarge,
    'md:mt-4': !isLarge,
    [styles.smallSalesPrice]: !isLarge,
  })
  const { dispatchSelectPromotionDataLayerEvent } = useProductDataLayer()
  const { sendClickEvent } = useAlgoliaSearchInsignts()
  const isSentPromoEvent = useRef(false)
  const BTN_TOP_LEFT = 'top-left'
  const BTN_TOP_RIGHT = 'top-right'
  const BTN_BOTTOM_LEFT = 'bottom-left'
  const BTN_BOTTOM_RIGHT = 'bottom-right'
  const product = useMemo(() => {
    const sku = skus?.[0]?.sku
    const title = titleText
    const url = shopNow?.shopNow?.href || ''

    const hasRequiredItemFields = sku || title

    if (!url.includes('/p/') || !hasRequiredItemFields) {
      return null
    }

    return {
      sku,
      title,
      finalPrice: price?.regular?.centAmount ? price.regular : price.final,
      brandName,
      category1Name,
      category2Name,
      category3Name,
      category4Name,
      category5Name,
      listId,
      listName,
      item_status_tags,
      index: /^\d+$/.test(creativeSlot) ? Number(creativeSlot) : 0,
    }
  }, [
    skus,
    titleText,
    price,
    brandName,
    category1Name,
    category2Name,
    category3Name,
    category4Name,
    category5Name,
    listId,
    listName,
    item_status_tags,
    creativeSlot,
    shopNow,
  ])
  const isPromotionOfferCard =
    !!promotionId && !!promotionName && !!creativeName && !!creativeSlot

  useEffect(() => {
    const payload = {
      promotionId,
      promotionName,
      creativeSlot,
      creativeName,
      product,
    }

    if (!isSentPromoEvent.current && isPromotionOfferCard) {
      GTM.dispatch(GTMEvent.CLEAR_ECOMMERCE)
      GTM.dispatch(GTMEvent.VIEW_PROMOTION, payload)
      isSentPromoEvent.current = true
    }
  }, [
    isPromotionOfferCard,
    product,
    promotionId,
    promotionName,
    creativeName,
    creativeSlot,
  ])

  const ITEMS_START = 'items-start'
  const ITEMS_CENTER = 'items-center'
  const ITEMS_END = 'items-end'
  const JUSTIFY_START = 'justify-start'
  const JUSTIFY_CENTER = 'justify-center'
  const JUSTIFY_END = 'justify-end'

  const imageStyles = {
    top: {
      left: { div: ITEMS_START, style: JUSTIFY_START },
      center: { div: ITEMS_START, style: JUSTIFY_CENTER },
      right: { div: ITEMS_START, style: JUSTIFY_END },
    },
    center: {
      left: { div: ITEMS_CENTER, style: JUSTIFY_START },
      center: { div: ITEMS_CENTER, style: JUSTIFY_CENTER },
      right: { div: ITEMS_CENTER, style: JUSTIFY_END },
    },
    bottom: {
      left: { div: ITEMS_END, style: JUSTIFY_START },
      center: { div: ITEMS_END, style: JUSTIFY_CENTER },
      right: { div: ITEMS_END, style: JUSTIFY_END },
    },
  }

  // Extract the vertical and horizontal positions
  const [vertical = 'center', horizontal = 'center'] = imagePosition
    ? imagePosition.split('-')
    : ['', '']

  // Set default styles if position is not found
  const defaultStyle = { div: ITEMS_CENTER, style: JUSTIFY_CENTER }

  // Assign styles based on the position or use default
  const positionStyle =
    imageStyles[vertical] && imageStyles[vertical][horizontal]
      ? imageStyles[vertical][horizontal]
      : defaultStyle
  const renderButton = (shopNow) => {
    return (
      <Button
        href={shopNow.shopNow.href}
        target={shopNow.openInNewWindow ? '_blank' : ''}
        fluid
        className={classNames('mt-2', styles.shopNow, {
          'lg:mt-3 md:mt-2.5': isLarge,
          'md:mt-2.5': !isLarge,
        })}
        size={shopNow.size}
        style={{
          backgroundColor: shopNow.buttonColor || '#FFFFFF',
          color: shopNow.buttonTextColor || '#0C254C',
        }}>
        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.BodyRegularBold}>
          <span
            className={classNames(BannerButtonSizeClassMap[shopNow.size])}
            style={{
              color: shopNow?.buttonTextColor,
            }}>
            {shopNow?.shopNow?.title || 'Shop Now'}
          </span>
        </Typography>
      </Button>
    )
  }
  const linkTarget = shopNow.openInNewWindow ? '_blank' : null

  return (
    <div
      className={classNames(
        'relative p-4 text-white rounded-xl flex flex-col w-full h-full overflow-clip',
        {
          [styles.root]: !backgroundColor,
          '2xl:p-12 md:p-6': isLarge,
          'md:p-6': !isLarge, // Small Variant
        }
      )}
      style={{ backgroundColor }}>
      {!!skus?.length && (
        <div
          className={classNames(
            'flex flex-col gap-1 absolute z-20 top-4 right-4 text-greyscale-50',
            {
              '2xl:top-12 2xl:right-12 md:top-6 md:right-6': isLarge,
              'md:top-6 md:right-6': !isLarge,
            }
          )}>
          {skus.map(({ url, sku, title }) => {
            return (
              <Link key={sku} to={url} title={title || ''} target={linkTarget}>
                <Typography
                  tag={TypographyTag.span}
                  variant={TypographyVariant.BodySmall}
                  style={typographyStyle}
                  className={classNames('block', styles.sku)}>
                  {sku}
                </Typography>
                <VisuallyHidden>{title}</VisuallyHidden>
              </Link>
            )
          })}
        </div>
      )}

      {/* Card Header */}
      <div className={classNames('flex gap-4 justify-between z-10')}>
        <div
          style={typographyStyle}
          className={classNames('flex flex-col gap-1.5 w-full', {
            'lg:gap-4 md:gap-2.5': isLarge,
            'md:gap-2.5': !isLarge, // Small Variant
          })}>
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodyRegularBold}
            style={typographyStyle}
            className={classNames(
              'text-greyscale-50 uppercase !font-bold',
              styles.label,
              {
                [styles.smallLabel]: !isLarge,
                [styles.largeLabel]: isLarge,
              }
            )}>
            {label}
          </Typography>

          {/* Rich Text Content */}
          {(title || description) && (
            <div style={richTextWrapperStyle} className={styles.heading}>
              {title && (
                <div
                  className={classNames(styles.title, {
                    [styles.largeTitle]: isLarge,
                    [styles.smallTitle]: !isLarge,
                  })}>
                  <RichText content={title} />
                </div>
              )}
              {description && (
                <div
                  className={classNames(
                    'hidden md:block mt-1.5',
                    styles.description,
                    {
                      'lg:mt-4 md:mt-2.5': isLarge,
                      [styles.largeDescription]: isLarge,
                      'md:mt-2.5': !isLarge, // Small Variant
                      [styles.smallDescription]: !isLarge,
                    }
                  )}>
                  <RichText content={description} />
                </div>
              )}
            </div>
          )}
        </div>
        {shopNow?.shopNow?.href && buttonPosition === BTN_TOP_RIGHT && (
          <div className={classNames('w-max mt-2')}>
            {renderButton(shopNow)}
          </div>
        )}
      </div>

      <div className='flex flex-1 justify-between'>
        <div className='flex flex-col w-max z-10'>
          <SalePrice
            price={price}
            saveText={saveText?.saveText}
            wasText={wasText}
            regularPriceClassName={priceStyles}
            finalPriceClassName={priceStyles}
            savePriceClassName={classNames(
              'mt-2 py-1.5 md:py-3 flex justify-center rounded uppercase !font-bold',
              styles.savePrice,
              {
                'lg:mt-6 md:mt-3': isLarge,
                'md:mt-3': !isLarge,
                [styles.largeSavePrice]: isLarge,
                [styles.smallSavePrice]: !isLarge,
              }
            )}
            textColor={textColor}
            wrapperClassName='block'
            regularPriceTypographyVariant={TypographyVariant.BodyXLarge}
            savePriceTypographyVariant={TypographyVariant.BodyLargeBold}
            saveUpToButtonStyle={saveText?.saveUptoButtonStyle}
            truncateDecimalZeros
          />
          {shopNow?.shopNow?.href &&
            (buttonPosition === BTN_TOP_LEFT || !buttonPosition) &&
            renderButton(shopNow)}

          <div className={classNames('flex-1 flex flex-col justify-end')}>
            {brandLogo?.altText && brandLogo?.src && (
              <Image
                alt={brandLogo?.altText}
                src={getImageSrc(brandLogo?.src, '100')}
                addSrcSet={false}
                width={brandLogo?.width}
                height={brandLogo?.height}
                className='object-contain max-md:w-20 mt-5 md:mt-2'
              />
            )}

            {disclaimer && (
              <Typography
                tag={TypographyTag.p}
                variant={TypographyVariant.BodyRegular}
                style={typographyStyle}
                className={classNames(
                  styles.disclaimer,
                  'mt-2 md:mt-3 text-greyscale-50',
                  {
                    [styles.smallDisclaimer]: !isLarge,
                    [styles.largeDisclaimer]: isLarge,
                    'mt-5': !brandLogo?.src,
                  }
                )}>
                *{disclaimer}
              </Typography>
            )}
          </div>
        </div>
        {/* Product Image */}
        <OfferImage image={image} imagePosition='cover' />
      </div>

      <div
        className={classNames(
          'absolute bottom-0 left-0 h-full w-full flex',
          positionStyle.div
        )}>
        <div
          className={classNames(
            'flex w-full h-full md:h-auto',
            positionStyle.style
          )}>
          <Image
            alt={productImage?.altText}
            width={productImage?.width}
            height={productImage?.height}
            src={getImageSrc(productImage?.src)}
            addSrcSet={false}
            className={classNames('md:!h-auto max-md:!w-auto !h-full')}
          />
        </div>
      </div>

      {shopNow?.shopNow?.href &&
        (buttonPosition === BTN_BOTTOM_LEFT ||
          buttonPosition === BTN_BOTTOM_RIGHT) && (
          <div
            className={classNames('w-full flex', {
              'justify-end': buttonPosition === BTN_BOTTOM_RIGHT,
              'justify-start': buttonPosition === BTN_BOTTOM_RIGHT,
            })}>
            <div className={classNames('w-max')}>{renderButton(shopNow)}</div>
          </div>
        )}

      {/* Sticker */}
      {(sticker?.content || sticker?.image?.src) && (
        <OfferSticker isLarge={isLarge} sticker={sticker} />
      )}

      {/* Overlay Link */}
      {shopNow?.shopNow?.href && (
        <Link
          to={shopNow.shopNow.href}
          title={shopNow.shopNow.title}
          target={linkTarget}
          className='absolute inset-0 z-10'
          onClick={() => {
            isPromotionOfferCard &&
              dispatchSelectPromotionDataLayerEvent({
                promotionId,
                promotionName,
                creativeSlot,
                creativeName,
                product,
              } as never)
            sendClickEvent([
              {
                title: shopNow.shopNow.title,
                url: shopNow.shopNow.href,
                listId: productId,
              },
            ])
          }}>
          <VisuallyHidden>{shopNow.shopNow.title}</VisuallyHidden>
        </Link>
      )}
    </div>
  )
}
