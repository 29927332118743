import classNames from 'classnames'
import { Image } from '~/components/Image'
import { getImageSrc } from '~/helpers'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './OfferCard.module.css'
import { OfferStickerProps } from './OfferCard.types'

export const OfferSticker = ({ sticker, isLarge }: OfferStickerProps) => {
  return (
    <div
      className={classNames(
        'rounded-full border-solid absolute grid place-content-center text-center p-2 bottom-4 right-4',
        styles.sticker,
        {
          'lg:bottom-12 lg:right-12 md:bottom-6 md:right-6': isLarge,
          'md:bottom-6 md:right-6': !isLarge, // Small Variant Styles
        }
      )}
      style={{
        borderWidth: sticker?.borderColor ? '5px' : 0,
        borderColor: sticker?.borderColor,
        color: sticker?.textColor,
        backgroundColor: sticker?.backgroundColor,
      }}>
      {sticker?.image?.altText && sticker?.image?.src && (
        <Image
          alt={sticker?.image?.altText}
          src={getImageSrc(sticker?.image?.src, '100')}
          addSrcSet={false}
          width={sticker?.image?.width}
          height={sticker?.image?.height}
          className='object-contain max-md:w-20 mt-5 md:mt-2'
        />
      )}
      <Typography
        tag={TypographyTag.p}
        variant={TypographyVariant.BodySmallBold}
        className={classNames(
          styles.stickerContent,
          '!font-bold text-greyscale-50'
        )}>
        {sticker.content}
      </Typography>
    </div>
  )
}
